<template>
  <div>
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <bfc-bar></bfc-bar>
    <admin-menu></admin-menu>
    <v-card flat class="py-12"> </v-card>
    <v-card>
      <v-card-title> </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6" sm="6">
            <v-text-field
              label="Doctor's name "
              v-model="filterName"
              hint="Doctor's name "
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="6">
            <!--v-date-picker v-model="dates" range></!--v-date-picker-->
            <v-menu
              ref="start"
              v-model="start"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateRangeText"
                  label="Select Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.start.save(dates)"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12"
            ><v-btn color="secondary" @click="searchReport"> Search</v-btn></v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12" md="12" sm="12">
            <!-- <v-data-table
              :headers="headers"
              :items="reportTable"
              :keys="id"
              v-if="reportTable.length > 0"
            >
            </v-data-table> -->
            <v-simple-table fixed-header
              ><template>
                <thead>
                  <tr>
                    <th class="text-center">No.</th>
                    <th class="text-center">Date</th>
                    <th class="text-center">Time</th>
                    <th class="text-center">Case No.</th>
                    <th class="text-center">Payment Method</th>
                    <th class="text-center">Doctor</th>
                    <th class="text-center">Amount (THB)</th>
                    <th class="text-center">Discount %</th>
                    <th class="text-center">Lab Fee (THB)</th>
                    <th class="text-center">Remark</th>
                  </tr>
                </thead>
                <tbody id="financeReport">
                  <tr v-for="(item, index) in reportTable" :key="index">
                    <td class="text-left">{{ index + 1 }}</td>
                    <td class="text-left">{{ item.date }}</td>
                    <td class="text-left">{{ item.time }}</td>
                    <td class="text-left">{{ item.caseNo }}</td>
                    <td class="text-left">
                      {{ item.payment_method }} ( {{ item.currency }} )
                    </td>
                    <td class="text-left">{{ item.doctor_name }}</td>
                    <td class="text-right">{{ item.amount }}</td>
                    <td class="text-right">{{ item.discount }}</td>
                    <td class="text-right">{{ item.labFee }}</td>
                    <td class="text-left">{{ item.description }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <td class="text-left"></td>
                  <td class="text-left"></td>
                  <td class="text-left"></td>
                  <td class="text-left"></td>
                  <td class="text-left"></td>
                  <td class="text-right">Summary</td>
                  <td class="text-right">{{ sumAmount }}</td>
                  <td class="text-right">{{ sumDiscount }}</td>
                  <td class="text-right">{{ sumFee }}</td>
                  <td class="text-left"></td></tfoot
              ></template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import SystemBar from "../../library/system-bar/system-bar";
import AdminMenu from "../menubar/menubar";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    "bfc-bar": SystemBar,
    "admin-menu": AdminMenu,
  },
  data() {
    return {
      url: process.env.VUE_APP_LOYALTY_API_URI,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      feedbackCaseNo: "",
      feedbackDialog: false,
      feedbackCaseId: "",
      toggle_exclusive: 0,
      start: false,
      dates: [], //new Date().toISOString().substring(0, 10)
      filterName: "",
      loading: false,
      cases: [],
      reportTable: [],
      headers: [
        {
          text: "Date",
          value: "updatedAt",
        },
        { text: "Time", value: "time" },
        {
          text: "Case No.",
          value: "caseNo",
        },
        { text: "Payment Method", value: "payment_method" },
        {
          text: "Doctor",
          value: "doctor_name",
        },
        {
          text: "Amount (THB)",
          value: "amount",
        },
        {
          text: "Discount %",
          value: "discount",
        },
        {
          text: "Lab fee (THB)",
          value: "labfee",
        },
        {
          text: "Remark",
          value: "description",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({}),
    sumAmount() {
      return this.reportTable.length > 0
        ? this.reportTable
            .map((item) => item.amount)
            .reduce((sum, current) => {
              //  console.log(sum + " " + current);
              return (parseFloat(sum) + parseFloat(current)).toFixed(2);
            })
        : 0;
    },
    sumDiscount() {
      return this.reportTable.length > 0
        ? this.reportTable
            .map((item) => item.discountTHB)
            .reduce((sum, current) => {
              //  console.log(sum + " " + current);
              return (parseFloat(sum) + parseFloat(current)).toFixed(2);
            })
        : 0;
    },
    sumFee() {
      return this.reportTable.length > 0
        ? this.reportTable
            .map((item) => item.labFee || 0)
            .reduce((sum, current) => {
              //  console.log(sum + " " + current);
              return (parseFloat(sum) + parseFloat(current)).toFixed(2);
            })
        : 0;
    },
    dateRangeText() {
      console.log(this.dates);
      if (this.dates != "") {
        return this.dates.join(" ~ ");
      } else {
        return "";
      }
    },
  },
  mounted() {
    // this.init();
    //this.reportTable = [];
  },
  methods: {
    ...mapActions({
      //setAdminCasesList: "BFC/setAdminCasesList",
    }),
    async searchReport() {
      console.log("initial data.....");
      this.loading = true;
      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/report/financialReport",
            {
              filter: this.filterName,
              dateRange: this.dates,
            }, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              this.cases = response.data.data;
              this.reportTable = this.cases
                .filter((item) => {
                  console.log(item.doctorId)
                  return item //item.doctorId != null;
                })
                .map((item) => {
                  //console.log(item.updatedAt.toString());
                  console.log(item);
                  let _package = {
                    text: "",
                    value: "",
                    price: 0,
                    labFee: 0,

                    packageStatus: "None",
                  };
                  // let selectPackage = "";
                  if (item.case != null) {
                    _package = item.case.package;
                  }

                  let doctorInfo = {
                      firstname : "",
                      lastname : "",
                      influencer : 0,
                      vip: 0,
                      usd: 0,
                    }
                  if(item.doctorId == null){
                      item.doctorId = doctorInfo;
                    }

                  // if (typeof _package.text !== "undefined") {
                  //   selectPackage = _package.text;
                  // }

                  // if (typeof item.package.text !== "undefined") {
                  //   selectPackage = item.package.text;
                  // }
                  if (item.doctorId.usd == 1) {
                    //
                    if( item.omiseWebhookDetail !== undefined) {
                    item.payment.price = item.omiseWebhookDetail.data.funding_amount / 100;
                    }
                    //item.payment.discount = 0;
                    item.currency = "USD";
                  } else {
                    item.currency = "THB";
                  }
                  
                  return {
                    id: item._id,
                    date: item.payment.paymentDate.toString().substring(0, 10),
                    time: item.payment.paymentDate.toString().substring(19, 11),
                    caseNo: item.caseNo,
                    payment_method: item.payment.paymentAgent,
                    labFee:
                      item.doctorId.influencer == 1
                        ? 0
                        : item.payment.price == 3000 || item.doctorId.influencer == 1
                        ? 0
                        : _package.labFee || 0,
                    description: item.payment.description,
                    doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
                    amount: item.payment.price,
                    discount: item.payment.discount,
                    discountTHB:
                      parseFloat(item.payment.price) *
                      (parseFloat(item.payment.discount) / 100),
                    currency: item.currency,
                  };
                });
              //console.log(this.reportTable);
              //this.setAdminCasesList(this.cases);
            } else {
              // show error
              console.log("error response");
            }
          });
        console.log(this.cases);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    prepareData() {
      this.reportTable = this.cases
        .filter((item) => {
          return item// item.doctorId != null;
        })
        .map((item) => {
          //console.log(item.updatedAt.toString());
          console.log(item);
          let _package = {
            text: "",
            value: "",
            price: 0,
            labFee: 0,
            packageStatus: "None",
          };
          // let selectPackage = "";
          if (item.case != null) {
            _package = item.case.package;
          }

          let doctorInfo = {
                      firstname : "",
                      lastname : "",
                      influencer : 0,
                      vip: 0,
                      usd: 0,
                    }
                  if(item.doctorId == null){
                      item.doctorId = doctorInfo;
                    }

          // if (typeof _package.text !== "undefined") {
          //   selectPackage = _package.text;
          // }

          // if (typeof item.package.text !== "undefined") {
          //   selectPackage = item.package.text;
          // }
          console.log( item.doctorId );
          if (item.doctorId.usd == 1) {
            //
            if( item.omiseWebhookDetail !== undefined) {
            item.payment.price = item.omiseWebhookDetail.data.funding_amount / 100;
            }
            //item.payment.discount = 0;
            item.currency = "USD";
            
          } else {
            item.currency = "THB";
          }

          return {
            id: item._id,
            date: item.payment.paymentDate.toString().substring(0, 10),
            time: item.payment.paymentDate.toString().substring(19, 11),
            caseNo: item.caseNo,
            payment_method: item.payment.paymentAgent,
            //labFee: item.payment.price == 3000 ? 0 : _package.labFee || 0,
            labFee:
              item.doctorId.influencer == 1
                ? 0
                : item.payment.price == 3000 || item.doctorId.influencer == 1
                ? 0
                : _package.labFee || 0,
            description: item.payment.description,
            doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
            amount: item.payment.price,
            discount: item.payment.discount,
            discountTHB:
              parseFloat(item.payment.price) * (parseFloat(item.payment.discount) / 100),
            currency: item.currency,

            //feedback: "",
            //selectPackage: selectPackage,
            //status: item.caseStatus.stateStatus,
            //_package: _package,
          };
        });
    },
  },
  watch: {
    toggle_exclusive: {
      handler() {
        //console.log(val);
        console.log(this.toggle_exclusive);
      },
    },
    filterName: {
      handler(val) {
        console.log("filterName " + val);
        this.reportTable = this.cases
          .filter((item) => {
            return item.doctorId != null;
          })
          .map((item) => {
            //console.log(item.updatedAt.toString());
            //console.log(item);
            let _package = {
              text: "",
              value: "",
              price: 0,
              packageStatus: "None",
            };

            if (item.case != null) {
              _package = item.case.package;
            }

            let doctorInfo = {
                      firstname : "",
                      lastname : "",
                      influencer : 0,
                      vip: 0,
                      usd: 0,
                    }
                  if(item.doctorId == null){
                      item.doctorId = doctorInfo;
                    }

            // if (typeof _package.text !== "undefined") {
            //   selectPackage = _package.text;
            // }

            // if (typeof item.package.text !== "undefined") {
            //   selectPackage = item.package.text;
            // }
            //console.log(_package);
            if (item.doctorId.usd == 1) {
            //
            if( item.omiseWebhookDetail !== undefined) {
            item.payment.price = item.omiseWebhookDetail.data.funding_amount / 100;
            }
            //item.payment.discount = 0;
            item.currency = "USD";
            
          } else {
            item.currency = "THB";
          }

            return {
              date: item.payment.paymentDate.toString().substring(0, 10),
              time: item.payment.paymentDate.toString().substring(19, 11),
              id: item._id,
              caseNo: item.caseNo,
              payment_method: item.payment.paymentAgent,
              labFee: item.payment.price <= 3000 ? 0 : _package.labFee || 0,
              description: item.payment.description,
              doctor_name: item.doctorId.firstname + " " + item.doctorId.lastname,
              amount: item.payment.price,
              discount: item.payment.discount,
              discountTHB:
                parseFloat(item.payment.price) *
                (parseFloat(item.payment.discount) / 100),
            };
          })
          .filter((item) => {
            //console.log(item);
            //console.log(val);
            return item.doctor_name.toLowerCase().indexOf(val.toLowerCase()) > -1;
          });
      },
    },
    cases: {
      handler(val) {
        console.log(val);
        if (typeof val === "undefined") {
          this.cases = [];
        }
        this.prepareData();
      },
    },
  },
};
</script>
<style scope>
/* table {
  width: 100%;
}

table td,
table th {
  border: 1px solid;
} */
th {
  text-align: center !important;
}

#financeReport tr:nth-child(2n-1) {
  background-color: #e3f2fd;
}
tfoot td {
  padding: 0 16px;
}
tfoot td {
  background-color: #0d47a1;
  color: #ffffff;
}
</style>
