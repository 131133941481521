<template>
  <div class="text-left">
    <v-bottom-navigation v-model="value" :background-color="color" dark shift>
      <v-btn @click="redirectTo('/dashboard')">
        <span>Dashboard</span>

        <v-icon>mdi-desktop-mac-dashboard </v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            <span>Users</span>
            <v-icon>mdi-account-group</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in menuItem" :key="index">
            <v-btn block depressed color="secondary" @click="redirectTo(item.path)">{{
              item.title
            }}</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <!--v-btn @click="redirectTo('/report')">
        <span>Report</span>

        <v-icon>mdi-finance</v-icon>
      </v-btn-->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            <v-icon>mdi-chart-line-variant</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in menuReportItem" :key="index">
            <v-btn block depressed color="secondary" @click="redirectTo(item.path)">{{
              item.title
            }}</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-btn @click="logout">
        <span>Logout</span>

        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "AdminMenu",
  data() {
    return {
      color: "primary",
      menuReportItem: [
        {
          title: "Admin's Report",
          path: "/report",
        },
        {
          title: "Inspector's Report",
          path: "/inspector/report",
        },

      ],
      menuItem: [
        {
          title: "Admin",
          path: "/users/admin",
        },
        {
          title: "Lab",
          path: "/users/lab",
        },
        {
          title: "Doctor",
          path: "/users/doctor",
        },
        {
          title: "Inspector",
          path: "/users/inspector",
        },
        // {
        //   title: "Exchange Rate",
        //   path: "/ex/rate",
        // },
      ],
    };
  },
  mounted() {
    if (this.$cookies.get("Auth").authType != "Admin") {
      this.logout();
    }
  },

  computed: {
    //...mapGetters({ getAuth: "BFC/getAuth" }),
  },
  methods: {
    ...mapActions({
      signout: "BFC/logout",
    }),

    redirectTo(path) {
      this.$router.push("/admin" + path);
    },

    logout() {
      //let redirectPath = "";
      // let auth = this.getAuth;
      // if (auth.authType == "Doctor") {
      //   redirectPath = "/doctor";
      // } else if (auth.authType == "Admin") {
      //   redirectPath = "/admin";
      // } else {
      //   redirectPath = "/";
      // }
      this.$cookies.set("Auth", {});
      this.signout();
      this.$router.push("/admin");
    },
  },
};
</script>
